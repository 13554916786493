<template>
    <div class="flex flex-col relative w-full">
        <label v-if="label" :class="['block text-sm font-medium mb-1', error ? 'text-red-500' : 'text-gray-700']">{{ label }} {{ required ? "*" : '' }}</label>
        <div :class="['flex w-full text-left items-center px-2 pt-2 text-blue-600 transition duration-100 ease-in-out bg-white cursor-pointer relative',
    isOpen ? 'rounded-t-md border-t border-l border-r' : 'rounded-md border', error ? 'border-red-500' : 'border-gray-300']"
             @click="isOpen = !isOpen">
            <div class="flex items-center justify-center absolute inset-0 bg-gray-200 opacity-50" v-if="loading"><i class="fas fa-spinner-third fa-spin text-gray-900"/></div>
            <div v-if="selected.length < 1" :class="['flex flex-1 text-gray-400', isOpen ? 'pt-2 pb-4' : 'pb-2']">{{ placeholder }}</div>
            <div v-else class="flex flex-1 text-gray-500 -ml-2 flex-wrap">
                <div v-for="(sel_option, index) in selected"
                     :key="'selected-option-' + index"
                     :class="['flex items-center ml-2 px-2 py-1 bg-blue-600 text-white rounded cursor-default', isOpen ? 'mb-2' : 'mb-2']"
                >
                    <div class="mr-2">{{ sel_option.text }}</div>
                    <i class="fal fa-times text-lg cursor-pointer" @click.stop="removeOption(sel_option)"/>
                </div>
            </div>
            <div><i :class="['fal text-gray-700', isOpen ? 'fa-angle-up' : 'fa-angle-down']"/></div>
        </div>
        <div v-if="isOpen" :class="['absolute left-0 right-0 bg-white border-l border-r border-b rounded-b-md z-10', error ? 'border-red-500' : 'border-gray-300']" style="top: 100%;">
            <div class="flex items-center px-2 mb-2">
                <input class="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none"
                       placeholder="Search"
                       v-model="searchTerm"
                >
                <div v-if="(orderedResults.length > 1 || selected.length > 1) && !onlyOneSelection" class="flex ml-2 space-x-1 whitespace-nowrap">
                    <div v-if="addAllVisible"
                         class="cursor-pointer hover:underline whitespace-nowrap"
                         @click="addAll">
                        Add all
                    </div>

                    <div v-if="removeAllVisible"
                         :class="['cursor-pointer hover:underline whitespace-nowrap', addAllVisible ? 'border-l border-gray-200 pl-1' : '']"
                         @click="removeAll">
                        Remove all
                    </div>
                </div>
            </div>
            <div v-if="orderedResults.length < 1"
                 class="px-3 py-2">
                <span v-if="searchTerm">There were no results found for "<span class="font-bold">{{ searchTerm }}</span>"</span>
                <span v-else>There are no results.</span>
            </div>
            <div v-else class="overflow-y-scroll" style="max-height: 205px;">
                <div v-for="(option, index) in orderedResults"
                     :key="'option-' + index"
                     @click="handleOptionClick(option)"
                     :class="['px-3 py-2 cursor-pointer', doesExist(option) ? 'bg-blue-500 text-white' : 'hover:bg-blue-200']"
                >
                    {{ option.text }}
                </div>
                <!--                <div class="absolute w-full h-14 bottom-0 bg-gradient-to-t from-gray-100 transition"/>-->
            </div>
        </div>
        <span v-if="error && !isOpen" class="mt-1 text-red-700 text-xs"><i class="fal fa-exclamation-triangle text-sm mr-2"/>{{ error }}</span>
    </div>

</template>

<script>
import {filter, find, orderBy} from "lodash";

export default {
    name: "select-multiple",
    props: {
        label: {type: String, default: ''},
        error: {type: String, default: ''},
        required: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
        onlyOneSelection: {type: Boolean, default: false},
        placeholder: {type: String, default: 'Select options'},
        options: {type: Array, default: () => []},  // [{value: 1, text: 'Some text'}],
        selectedOptions: {type: Array, default: () => []} // [{value: 1, text: 'Some text'}],
    },
    data() {
        return {
            isOpen: false,
            selected: [],
            searchTerm: '',
            results: []
        }
    },

    computed: {
        addAllVisible() {
            return this.results.length > 1 && this.results.length !== this.selected.length
        },

        removeAllVisible() {
            return this.selected.length > 1
        },

        orderedResults() {
            return orderBy(this.results, ['text'], ['asc'])
        }
    },

    watch: {
        searchTerm() {
            if (this.searchTerm) {
                this.results = filter(this.options, option => option.text.toLowerCase().includes(this.searchTerm.toLowerCase()))
            } else {
                this.results = this.options
            }
        },

        options(newOptions) {
            this.results = newOptions
        },

        selectedOptions(newOptions) {
            this.selected = newOptions
        },

        selected(newOptions) {
            this.$emit('on-change', newOptions)
        }
    },

    methods: {
        handleOptionClick(option) {
            if (this.doesExist(option)) {
                this.removeOption(option)
            } else {
                if (this.$props.onlyOneSelection) {
                    this.selected = [option];
                    this.isOpen = false;
                } else {
                    this.addOption(option)
                }
            }
        },

        removeOption(option) {
            this.selected = filter(this.selected, s_option => s_option.value !== option.value);
        },

        addOption(option) {
            this.selected = [...this.selected, option];
        },

        doesExist(option) {
            return find(this.selected, s_option => s_option.value === option.value)
        },

        addAll() {
            this.selected = this.results
        },

        removeAll() {
            this.selected = []
        }
    },

    created() {
        this.selected = this.$props.selectedOptions
        this.results = this.$props.options
    }
}
</script>

<style scoped>

</style>